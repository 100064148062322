@import '@/assets/styles/variables';
@import '@/assets/styles/media';
@import '@/assets/styles/hover';

.swiper {
  @include tablet() {
    gap: toVh(30) !important;
  }
}

.accordion {
  z-index: 0;
  position: relative;
  overflow: hidden;
  padding: toVh(20) 0 toVh(20);

  &__head {
    flex: 0 0 50%;

    &-services {
      flex: initial;
      margin: 0 toVh(30) 0 0;
    }

    span {
      color: $text-primary_2;
    }
  }

  &-border {
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      top: 0;
      height: 1px;
      background: $bg-secondary_1;
      z-index: 1;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      background: $bg-secondary_1;
      z-index: 1;
      bottom: 0;
    }
  }

  & + & {
    margin-top: -1px;
  }

  @include on-hover {
    color: $text-primary_2;
    z-index: 1;

    /*.price {
      .text {
        color: $text-primary_2;
      }

      .circle {
        background-color: $text-primary_2;
      }
    }*/
  }

  &__body {
    color: $text-primary_1;
    max-height: 0;
    opacity: 0;
    font-weight: 400;
    font-size: toVh(18);
    transition: all 0.5s cubic-bezier(0, 1, 0, 1);

    @include roboto();

    &-opened {
      opacity: 1;
      max-height: toVh(999);
      transition: max-height 1s ease-in-out;
      margin-top: toVh(32);
    }

    &-list {
      display: flex;
      flex-direction: column;
      gap: toVh(24);
      list-style: none;

      li {
        font-weight: 400;
        font-size: toVh(18);
        position: relative;
        display: flex;
        align-items: flex-start;
        gap: toVh(12);

        @include roboto();

        &::before {
          content: '';
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: toVh(14);
          display: block;
          width: toVh(14);
          height: toVh(14);
          border-radius: 50%;
          border: 1px solid $bg-primary_2;
          transform: translateY(toVh(6));
        }
      }
    }

    &-list-inline {
      flex-direction: row;
      flex-wrap: wrap;
      gap: toVh(16);

      li:first-child {
        &::before {
          display: none;
        }
      }
    }
  }

  @media (max-height: 1080px) {
    &__head {
      flex-basis: 33%;
    }
  }

  @include small-desktop() {
    &__head {
      flex-basis: 50%;
    }
  }

  @include tablet() {
    padding: toVh(12) 0 toVh(12) !important;

    &__body {
      line-height: toVh(28);
      font-size: toVh(16);

      &-list {
        & li {
          font-size: toVh(15);
        }
      }

      &-opened {
        margin-top: toVh(12);
      }
    }
  }
}

.title-wrap {
  display: flex;
}

.stack {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: toVh(10);

  & li {
    display: flex;
    align-items: center;
    gap: toVh(8);
    font-style: normal;
    font-weight: 400;
    font-size: toVh(18);
    line-height: 1.5;
    color: $text-primary_1;
    position: relative;
    text-transform: none;
    white-space: nowrap;

    @include roboto();

    &:before {
      content: '';
      display: block;
      flex: 0 0 toVh(14);
      width: toVh(14);
      height: toVh(14);
      border-radius: 50%;
      border: 1px solid $bg-primary_2;
    }
  }

  & span {
    display: flex;
    gap: toVh(10);
    font-style: normal;
    font-weight: 400;
    font-size: toVh(18);
    line-height: 1.5;
    color: $text-primary_1;
    text-transform: none;

    @include roboto();

    & > :last-child {
      color: $text-primary_2;
    }
  }

  @include tablet() {
    & li {
      font-size: toVh(15);
    }
  }
}

.price {
  display: flex;
  align-items: center;
  gap: toVh(16);
  max-width: toVh(175);
  width: 100%;
  white-space: nowrap;

  .circle {
    width: toVh(14);
    height: toVh(14);
    border-radius: 50%;
    background-color: $bg-secondary_2;
    transition: 0.3s;
  }

  .text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: toVh(18);
    line-height: 2;
    color: $text-primary_1;
    text-transform: none;
    transition: 0.3s;
  }

  /*&__active {
    .text {
      color: $text-primary_2 !important;
    }

    .circle {
      background-color: $text-primary_2 !important;
    }
  }*/

  @include tablet() {
    margin-bottom: toVh(10);
    max-width: initial;

    .text {
      line-height: 1.1;
      font-size: toVh(15);
    }
  }
}
